<template>
    <div v-if="isMobile()" class="mb-content">
        <div class="list">
            <div class="item"><span>您的姓名</span><input type="text" v-model="params.name"><b>*</b></div>
            <div class="item"><span>联系电话</span><input type="number" v-model="params.phone"><b>*</b></div>
            <div class="item"><span>QQ号码</span><input type="number" v-model="params.qq"><b>*</b></div>
            <div class="item"><span>电子邮箱</span><input type="text" v-model="params.mailbox"><b>*</b></div>
            <div class="item"><span>了解途径</span>
                <select v-model="params.knowType" placeholder="请选择">
                    <option :value ="1">网上广告</option>
                    <option :value ="2">街头传单</option>
                    <option :value="3">朋友介绍</option>
                </select>
                <b>*</b>
            </div>
            <div class="error" v-if="text">请输入{{text}}</div>
            <div :class="['submit',text==='提交成功'?'green':'']" @click="save">提交</div>
        </div>
    </div>
    <div class="content" v-else>
        <div class="header"><img src="../../assets/images/header4.jpg" alt=""></div>
        <div class="concat">
            <transition name="contact-slide-fade">
                <div class="title" v-if="show">
                    <h3>广西中科信恒投资集团</h3>
                    <p class="text">致力打造国际一流的综合投资服务商业平台</p>
                </div>
            </transition>
            <div class="addr">
                <transition name="contact-slide-fade">
                    <div v-if="show">电话： 0771-3924090</div>
                </transition>
                <transition name="contact-slide-fade">
                    <div v-if="show">网址：www.gxzkxh.com</div>
                </transition>
                <transition name="contact-slide-fade">
                    <div v-if="show">地址：广西南宁市青秀区中柬路8号龙光世纪A座7楼707室</div>
                </transition>
            </div>
            <div class="form">
                <div class="list">
                    <transition name="contact-slide-fade">
                    <div class="item" v-if="show"><span>您的姓名</span><input type="text" v-model="params.name"><b>*</b></div>
                    </transition>
                    <transition name="contact-slide-fade">
                    <div class="item" v-if="show"><span>联系电话</span><input type="number" v-model="params.phone"><b>*</b></div>
                    </transition>
                    <transition name="contact-slide-fade">
                    <div class="item" v-if="show"><span>QQ号码</span><input type="number" v-model="params.qq"><b>*</b></div>
                    </transition>
                    <transition name="contact-slide-fade">
                    <div class="item" v-if="show"><span>电子邮箱</span><input type="text" v-model="params.mailbox"><b>*</b></div>
                    </transition>
                    <transition name="contact-slide-fade">
                    <div class="item" v-if="show"><span>了解途径</span>
                        <select v-model="params.knowType">
                            <option :value ="1">网上广告</option>
                            <option :value ="2">街头传单</option>
                            <option :value="3">朋友介绍</option>
                        </select>
                        <b>*</b>
                    </div>
                    </transition>
                    <div class="error" v-if="text">请输入{{text}}</div>
                    <transition name="contact-slide-fade">
                    <div :class="['submit',text==='提交成功'?'green':'']" v-if="show" @click="save">提交</div>
                    </transition>
                </div>
                
                <transition name="contact-slide-fade">
                    <div class="map" v-if="show">
                        <div id="container" style="width: 500px;height: 350px"></div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import BMap from 'BMap'
import { saveContact } from '@/api/contact'
export default {
    setup() {
        const show = ref(false)
        const params = ref({
            name: "",
            phone: "",
            qq: "",
            mailbox: "",
            knowType: ""
        })
        const text = ref('')

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const save = async() => {
            if(!params.value.name) {
                text.value = "请输入姓名"
            }else if(!params.value.phone) {
                text.value = "请输入电话"
            }else if(!params.value.qq) {
                text.value = "请输入qq"
            }else if(!params.value.mailbox) {
                text.value = "请输入邮箱"
            }else if(!params.value.knowType) {
                text.value = "请选择了解途径"
            }else {
                let data = await saveContact(params.value)
                if(data.status === 200) {
                    text.value = "提交成功"
                }
            }
        }
        
        
        onMounted(() => {
            if(!isMobile()) {
                setTimeout(() => {
                    show.value = true
                }, 50);

                setTimeout(() => {
                    var map = new BMap.Map('container');
                    map.centerAndZoom(new BMap.Point(108.405273,22.814737), 13);
                    var point = new BMap.Point(108.405273,22.814737);    
                    map.enableScrollWheelZoom(true);
                    var marker = new BMap.Marker(point);
                    map.addOverlay(marker);
                    var opts = {
                        position: new BMap.Point(108.405273,22.814737), // 指定文本标注所在的地理位置
                        offset: new BMap.Size(30, -30) // 设置文本偏移量
                    };
                    // 创建文本标注对象
                    var label = new BMap.Label(
                        `广西中科信恒投资集团有限公司 <br />
                        <p style="font-weight:normal">青秀区中柬路8号龙光世纪A座7楼707室</p>
                        <b class="down"></b>`,
                        opts
                    );
                    // 自定义文本标注样式
                    label.setStyle({
                        borderRadius: '5px',
                        padding: '10px',
                        fontSize: '16px',
                        width: '300px',
                        height: '60px',
                        fontWeight: 'bold',
                        lineHeight: '25px',
                        fontFamily: '微软雅黑'
                    });
                    map.addOverlay(label);
                }, 80);
            }
        })

        return {
            show,
            params,
            text,
            save,
            isMobile
        }
    },
}
</script>
<style lang="scss">
    .BMapLabel {
        border: none !important;
        box-shadow: 0 0 6px -3px #000;
        margin-left: -190px;
        margin-top: -90px;
    }
    .concat .down {
        display: inline-block;
        border: 10px solid transparent;
        border-top: 12px solid #fff;
        margin-bottom: -25px;
        margin-left: 138px;
    }
</style>
<style lang="scss" scoped>
    .content {
        min-height: 680px;
    }
    .header {
        img {
            width: 100%;
            height: 530px;
            vertical-align: top;
        }
    }
    .concat {
        width: 1100px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 30px;
        .title {
            display: inline-block;
        }
        h3 {
            font-size: 31px;
            margin-bottom: 10px;
        }
        .text {
            padding-bottom: 30px;
            margin-bottom: 40px;
            font-size: 16px;
            position: relative;
        }
        .text::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 1px solid #d1d1d1;
            width: 50px;
        }
        .addr {
            display: flex;
            padding-bottom: 50px;
            border-bottom: 1px solid #e1e1e1;
            >div {
                margin-right: 120px;
            }
            >div:last-child {
                margin: 0;
            }
        }
        .form {
            display: flex;
            margin-top: 60px;
            .list {
                flex: 1;
                .item {
                    display: flex;
                    line-height: 38px;
                    margin-bottom: 25px;
                    span {
                        display: inline-block;
                        width: 80px;
                        font-weight: 700;
                        color: #666666;
                        text-align: right;
                        padding-right: 10px;
                    }
                    input {
                        height: 40px;
                        width: 380px;
                        padding: 0 10px;
                        font-size: 16px;
                        border: 1px solid #dadada;
                        border-radius: 4px;
                        overflow: hidden;
                        outline:none; 
                    }
                    select {
                        width: 400px;
                        height: 40px;
                        border: 1px solid #dadada;
                        border-radius: 4px;
                        padding: 0 10px;
                    }
                    b {
                        display: inline-block;
                        color: red;
                        margin-left: 15px;
                        font-weight: normal;
                    }
                }
                .error {
                    text-align: center;
                    color: red;
                    margin-bottom: 20px;
                }
                .submit {
                    width: 200px;
                    line-height: 45px;
                    text-align: center;
                    background: #8e8e8e;
                    color: #fff;
                    margin: 0 auto;
                    cursor: pointer;
                }
                .green {
                    color: green;
                }
            }
            .map {
                flex: 1;
            }
        }
        .contact-slide-fade-enter-active {
            animation: bounce-in .6s linear;
        }
        @keyframes bounce-in {
            0% {
                transform: rotate3d(0, 1, 0.05, 90deg);
                opacity: 0.5;
            }
            60% {
                transform: rotate3d(0, 1, 0.1, -25deg);
                opacity: 1;
            }
            75% {
                transform: rotate3d(0, 1, 0.1, 25deg);
            }
            90% {
                transform: rotate3d(0, 1, 0.1, -25deg);
            }
            100% {
                transform: rotate3d(0,0,0,0);
            }
        }
    }
    .mb-content {
        .list {
            flex: 1;
            margin-top: 0.3rem;
            .item {
                display: flex;
                line-height: 38px;
                margin-bottom: 0.3rem;
                font-size: 0.28rem;
                span {
                    display: inline-block;
                    width: 1.6rem;
                    font-weight: 700;
                    color: #666666;
                    text-align: right;
                    padding-right: 10px;
                }
                input {
                    height: 40px;
                    width: calc(100% - 1.6rem - 80px);
                    padding: 0 10px;
                    font-size: 16px;
                    border: 1px solid #dadada;
                    border-radius: 4px;
                    overflow: hidden;
                    outline:none; 
                }
                select {
                    width: calc(100% - 1.6rem - 57px);
                    height: 40px;
                    border: 1px solid #f1f1f1;
                    background: #f1f1f1;
                    border-radius: 4px;
                    padding: 0 10px;
                }
                b {
                    display: inline-block;
                    color: red;
                    margin-left: 15px;
                    font-weight: normal;
                }
            }
            .error {
                text-align: center;
                color: red;
                margin-bottom: 20px;
            }
            .submit {
                width: calc(100% - 0.6rem);
                line-height: 45px;
                text-align: center;
                background: #25477f;
                border-radius: 0.1rem;
                color: #fff;
                margin: 0 auto;
                cursor: pointer;
                margin-top: 1rem;
            }
            .green {
                color: green;
            }
        }
    }
</style>